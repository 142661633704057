import React from "react";

const LogoSmall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 135 78"
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
    >
      <path d="M56.11 36.95C70.46 40.83 85.74 39.27 99 32.58C104.12 29.25 109.06 25.64 113.79 21.77C114.94 20.93 115.19 19.32 114.34 18.19C114.26 18.07 114.16 17.96 114.06 17.86C106.07 11.37 95.57 1.46 69.67 3.22C53.4 4.24 40.43 14.98 36.51 18.57C35.82 19.22 35.77 20.29 36.38 21.01C39.57 24.6 49.01 34.75 56.11 36.95Z" />
      <path d="M115.28 23.44C112.86 25.23 109.26 28.04 105.91 30.42C107.53 31.78 111.95 36.19 112.72 36.82C114.31 38.1 116 40.37 118.92 40.06C122.93 39.62 131.89 36.58 131.87 32.55C131.84 28.51 126.63 23.3 123.95 22.53C122.19 22.4 120.42 22.64 118.75 23.22C117.63 23.58 116.44 23.66 115.28 23.44Z" />
      <path d="M68.56 42.4C68.56 42.4 75.41 42.25 77.39 42.04C84.67 41.48 91.7 39.16 97.89 35.3C84.39 41.32 69.21 42.46 54.96 38.54C48.06 36.31 38.91 26.68 35.19 22.5C35.07 22.65 34.15 23.83 34.03 23.98C32.75 25.52 30.47 25.73 28.93 24.46C28.75 24.31 28.59 24.15 28.45 23.98C23.51 20.02 16.84 18.99 10.95 21.27C3.7 24.24 1.92 32.55 1.87 37.38C1.87 39.79 9.11 46.27 14.38 46.79C21.05 47.58 27.7 45.09 32.22 40.11C34 38.25 36.49 37.24 39.08 37.33C42.67 36.93 46.28 38.07 49 40.47C48.37 44.45 43.93 46.77 44.46 51.07C45.26 57.79 53.06 74.45 59.6 75.56C68.7 77.1 92.75 72.78 92.44 64.54C92.39 62.03 85.94 61.19 80.15 60.13C70.8 58.56 67.47 51.62 65.87 47.04C65.06 44.62 68.02 43.33 68.56 42.4Z" />
    </svg>
  );
};

export default LogoSmall;
