import React from 'react';
import { createMuiTheme, ThemeProvider, CssBaseline } from '@material-ui/core';
import Root from './Root';
import { blue, cyan } from '@material-ui/core/colors';
import { CustomerProvider } from './Customer';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: blue[500],
      main: blue[700],
      dark: blue[900]
    },
    secondary: cyan
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 550,
      md: 900,
      lg: 1280,
      xl: 1920
    }
  }
})

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CustomerProvider>
        <Root />
      </CustomerProvider>
    </ThemeProvider>
  )
}

export default App;