import React from "react";
import { Formik, Form } from "formik";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import StripeCardInput from "./StripeCardInput";
import firebase from "../firebase";
import { CUSTOMERS, PAYMENT_METHODS } from "../constants";
import { useCustomer } from "./Customer";
import { useSnackbar } from "./Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    "& .divider": {
      width: 100,
      height: 2,
      background: theme.palette.primary.main,
      display: "block",
      margin: `${theme.spacing(1)}px 0`,
    },
    "& .actions": {
      marginTop: theme.spacing(2),
    },
  },
  bigButton: {
    background: theme.palette.common.white,
    width: "100%",
    maxWidth: 400,
    height: 300,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export interface PaymentMethod {
  id: string;
  brand: string;
  last4: string;
  expMonth: number;
  expYear: number;
}
const PaymentMethodDialog: React.FC<{ ButtonComponent: JSX.Element }> = ({
  ButtonComponent,
}) => {
  const [open, setOpen] = React.useState(false);
  const [cardError, setCardError] = React.useState(
    "Your card number is incomplete."
  );
  const customer = useCustomer();
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const { setSnackbarMessage } = useSnackbar();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const WrappedButtonComponent = React.cloneElement(ButtonComponent, {
    onClick: handleOpen,
  });
  return (
    <>
      {WrappedButtonComponent}
      <Dialog open={open} disableBackdropClick fullWidth maxWidth="sm">
        <div className={classes.root}>
          <Typography variant="h6">Add Payment Method</Typography>
          <span className="divider" />
          <Formik
            initialValues={{ card: "" }}
            onSubmit={async () => {
              try {
                if (!stripe || !elements || !customer.customerData) {
                  throw new Error("Card form not available");
                }
                const card = elements.getElement(CardElement);
                if (!card) {
                  throw new Error("Card could not be saved");
                }
                const paymentMethodData = await stripe.createPaymentMethod({
                  type: "card",
                  card,
                });
                if (
                  paymentMethodData &&
                  paymentMethodData.paymentMethod &&
                  paymentMethodData.paymentMethod.card
                ) {
                  const paymentMethod: PaymentMethod = {
                    id: paymentMethodData.paymentMethod.id,
                    brand: paymentMethodData.paymentMethod.card.brand,
                    last4: paymentMethodData.paymentMethod.card.last4,
                    expMonth: paymentMethodData.paymentMethod.card.exp_month,
                    expYear: paymentMethodData.paymentMethod.card.exp_year,
                  };
                  await Promise.all([
                    firebase
                      .firestore()
                      .collection(CUSTOMERS)
                      .doc(customer.customerData.uid)
                      .collection(PAYMENT_METHODS)
                      .doc(paymentMethod.id)
                      .set(paymentMethod),
                    firebase
                      .firestore()
                      .collection(CUSTOMERS)
                      .doc(customer.customerData.uid)
                      .update({ defaultPaymentMethodId: paymentMethod.id }),
                  ]);
                  customer.setCustomerData((data) => {
                    if (data) {
                      return {
                        ...data,
                        defaultPaymentMethodId: paymentMethod.id,
                        paymentMethods: [...data.paymentMethods, paymentMethod],
                      };
                    }
                    return data;
                  });
                  setSnackbarMessage("Payment method created");
                  handleClose();
                } else {
                  if (!card) {
                    throw new Error("Card could not be saved");
                  }
                }
              } catch (err) {
                setCardError(err.message);
              }
            }}
          >
            {({ setFieldTouched, touched, submitCount, isSubmitting }) => {
              return (
                <Form>
                  <StripeCardInput
                    setCardError={setCardError}
                    cardError={cardError}
                    hasError={Boolean(
                      (touched.card || submitCount > 0) && cardError
                    )}
                    handleBlur={() => setFieldTouched("card", true)}
                  />
                  <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    className="actions"
                  >
                    <Grid item>
                      <Button
                        variant="outlined"
                        type="button"
                        onClick={handleClose}
                      >
                        CANCEL
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                        endIcon={
                          isSubmitting ? <CircularProgress size={20} /> : null
                        }
                      >
                        {isSubmitting ? "SAVING" : "SAVE CARD"}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </>
  );
};

export default PaymentMethodDialog;
