import React from "react";
import { Formik, Form } from "formik";
import { useStripe } from "@stripe/react-stripe-js";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import {
  convertCentsToCurrency,
  convertPriceToCents,
  PRICE_REG_EX,
} from "../utils";
import { green } from "@material-ui/core/colors";
import PaymentSuccessDialog from "./PaymentSuccessDialog";
import FormSelectField from "./FormSelectField";
import FormPriceField from "./FormPriceField";
import { useCustomer } from "./Customer";
import PaymentMethodDialog from "./PaymentMethodDialog";
import firebase from "../firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    "& .status": {
      fontWeight: "bold",
      "&.paid": {
        color: green[700],
      },
      "&.overdue": {
        color: theme.palette.error.main,
      },
    },
    "& .mt-2": {
      marginTop: theme.spacing(2),
    },
  },
  divider: {
    width: "100%",
    height: 1,
    background: theme.palette.grey[300],
    display: "block",
    margin: `${theme.spacing(1)}px 0`,
  },
  progressContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1),
  },
  balanceBtn: {
    color: theme.palette.primary.main,
    background: "none",
    border: "none",
    "&:hover": {
      color: theme.palette.primary.dark,
    },
    "&:focus": {
      color: theme.palette.primary.dark,
    },
    cursor: "pointer",
  },
}));

export interface Invoice {
  category: string;
  createdAt: number;
  total: number;
  amountPaid: number;
  amountDue: number;
  status: "Paid" | "Not Paid" | "Overdue";
  dueDate: number;
  paidAt: number | null;
  id?: string;
  notes?: string;
  companyName: string;
  [key: string]: string | number | null | undefined;
}
export interface InvoiceProp {
  label: string;
  key: string;
  renderFunction?: (val: any) => string | JSX.Element;
}
export const defaultInvoiceProps: InvoiceProp[] = [
  {
    label: "Category",
    key: "category",
  },
  {
    label: "Date Issued",
    key: "createdAt",
    renderFunction: (val: number) => `${moment(val).format("MM-DD-YYYY")}`,
  },
  {
    label: "Total",
    key: "total",
    renderFunction: (val: number) => `$${convertCentsToCurrency(val)}`,
  },
  {
    label: "Status",
    key: "status",
    renderFunction: (val: string) => (
      <span className={`status ${val.toLowerCase().replace(/ /g, "")}`}>
        {val}
      </span>
    ),
  },
  {
    label: "Amount Paid",
    key: "amountPaid",
    renderFunction: (val: number) => `$${convertCentsToCurrency(val)}`,
  },
  {
    label: "Amount Due",
    key: "amountDue",
    renderFunction: (val: number) => `$${convertCentsToCurrency(val)}`,
  },
  {
    label: "Due Date",
    key: "dueDate",
    renderFunction: (val: number) => `${moment(val).format("MM-DD-YYYY")}`,
  },
  {
    label: "Date Paid",
    key: "paidAt",
    renderFunction: (val: number) =>
      val ? `${moment(val).format("MM-DD-YYYY")}` : "N/A",
  },
];
interface InvoiceDialogProps {
  activeInvoice: Invoice | null;
  setActiveInvoice: (invoice: Invoice | null) => void;
}

const InvoiceDialog: React.FC<InvoiceDialogProps> = ({
  activeInvoice,
  setActiveInvoice,
}) => {
  const stripe = useStripe();
  const classes = useStyles();
  const customer = useCustomer();
  const [open, setOpen] = React.useState(false);
  const [paymentSuccess, setPaymentSuccess] = React.useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  React.useEffect(() => {
    if (activeInvoice) {
      setOpen(true);
    }
  }, [activeInvoice]);
  React.useEffect(() => {
    if (paymentSuccess) {
      setOpen(false);
    }
  }, [paymentSuccess]);
  function handleExited() {
    setActiveInvoice(null);
    if (paymentSuccess) {
      setSuccessDialogOpen(true);
    }
  }
  function handleCloseSuccessDialog() {
    setPaymentSuccess(false);
    setSuccessDialogOpen(false);
  }
  function handleClose() {
    setOpen(false);
  }
  if (!customer.customerData) {
    return null;
  }
  const paymentMethods = customer.customerData.paymentMethods.map((method) => ({
    value: method.id,
    label: `${method.brand.toUpperCase()} ...${method.last4}`,
  }));
  return (
    <>
      {activeInvoice && (
        <Dialog
          open={open}
          onExited={handleExited}
          disableBackdropClick
          maxWidth="sm"
          fullWidth
        >
          <div className={classes.root}>
            <Grid container alignItems="center">
              {defaultInvoiceProps.map(({ label, key, renderFunction }) => {
                return (
                  <Grid item key={key} xs={6}>
                    <Typography variant="caption" color="textSecondary">
                      {label}
                    </Typography>
                    <Typography gutterBottom>
                      {renderFunction
                        ? renderFunction(activeInvoice[key])
                        : activeInvoice[key]}
                    </Typography>
                  </Grid>
                );
              })}
              {activeInvoice.notes && (
                <Grid item>
                  <Typography variant="caption" color="textSecondary">
                    Notes
                  </Typography>
                  <Typography gutterBottom>{activeInvoice.notes}</Typography>
                </Grid>
              )}
            </Grid>
            <span className={classes.divider} />
            {activeInvoice.amountDue ? (
              <>
                {paymentMethods.length > 0 ? (
                  <Formik
                    initialValues={{
                      paymentMethodId:
                        paymentMethods.length === 1
                          ? paymentMethods[0].value
                          : "",
                      amount: "",
                    }}
                    validate={(values) => {
                      const errors: { [key: string]: string } = {};
                      if (!values.paymentMethodId) {
                        errors.paymentMethodId =
                          "Please select a payment method";
                      }
                      if (PRICE_REG_EX.test(values.amount) === false) {
                        errors.amount =
                          "Please enter an amount with dollars and cents";
                      } else {
                        const priceInCents = convertPriceToCents(values.amount);
                        if (priceInCents > activeInvoice.amountDue) {
                          errors.amount =
                            "You cannot pay more than is due ($" +
                            convertCentsToCurrency(activeInvoice.amountDue) +
                            ")";
                        } else if (priceInCents < 50) {
                          errors.amount = "You must pay at least $0.50";
                        }
                      }
                      return errors;
                    }}
                    onSubmit={async (values, actions) => {
                      try {
                        if (!stripe) {
                          throw new Error(
                            "Cannot process payments at thie time"
                          );
                        }
                        actions.setStatus(null);
                        const createPaymentIntent = firebase
                          .functions()
                          .httpsCallable("createPaymentIntent");
                        const { data } = await createPaymentIntent({
                          amount: values.amount,
                          invoiceId: activeInvoice.id,
                        });
                        const result = await stripe.confirmCardPayment(
                          data.clientSecret,
                          {
                            payment_method: values.paymentMethodId,
                          }
                        );
                        if (result.error) {
                          actions.setStatus(result.error.message);
                          actions.setSubmitting(false);
                        } else {
                          setPaymentSuccess(true);
                        }
                      } catch (err) {
                        actions.setStatus(err.message);
                        actions.setSubmitting(false);
                      }
                    }}
                  >
                    {({ isSubmitting, status, setFieldValue }) => {
                      const handleBalanceClick = () => {
                        setFieldValue(
                          "amount",
                          convertCentsToCurrency(activeInvoice.amountDue)
                        );
                      };
                      return (
                        <>
                          {status && (
                            <Typography color="error" gutterBottom>
                              An error occurred - {status}
                            </Typography>
                          )}
                          <Form>
                            {isSubmitting ? (
                              <div>
                                <div className={classes.progressContainer}>
                                  <CircularProgress size={40} />
                                </div>
                                <Typography align="center">
                                  Please wait while we process your payment...
                                </Typography>
                              </div>
                            ) : (
                              <>
                                <Typography variant="h6" color="primary">
                                  <strong>Make a Payment</strong>
                                </Typography>
                                <FormSelectField
                                  label="Payment Method"
                                  options={paymentMethods}
                                  name="paymentMethodId"
                                />
                                <FormPriceField
                                  label="Amount to Pay"
                                  name="amount"
                                  helperText={
                                    <button
                                      type="button"
                                      className={classes.balanceBtn}
                                      onClick={handleBalanceClick}
                                    >
                                      Click to pay remaining balance
                                    </button>
                                  }
                                />
                                <Grid
                                  container
                                  alignItems="center"
                                  justify="space-between"
                                  className="mt-2"
                                >
                                  <Grid item>
                                    <Button
                                      variant="outlined"
                                      onClick={handleClose}
                                      type="button"
                                    >
                                      CLOSE
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      type="submit"
                                    >
                                      SUBMIT PAYMENT
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </Form>
                        </>
                      );
                    }}
                  </Formik>
                ) : (
                  <div>
                    <Typography gutterBottom color="primary">
                      To make a payment, please add a payment method.
                    </Typography>
                    <Grid
                      container
                      alignItems="center"
                      justify="space-between"
                      className="mt-2"
                    >
                      <Grid item>
                        <Button variant="outlined" onClick={handleClose}>
                          CLOSE
                        </Button>
                      </Grid>
                      <Grid item>
                        <PaymentMethodDialog
                          ButtonComponent={
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<AddIcon />}
                            >
                              ADD PAYMENT METHOD
                            </Button>
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}
              </>
            ) : (
              <Grid container alignItems="center" justify="flex-end" className="mt-2" spacing={2}>
                <Grid item>
                  <Button variant="outlined" onClick={handleClose}>
                    CLOSE
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
        </Dialog>
      )}
      <PaymentSuccessDialog
        open={successDialogOpen}
        handleClose={handleCloseSuccessDialog}
      />
    </>
  );
};

export default InvoiceDialog;
