import React from "react"

const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 600 78"
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
    >
      <path d="M346.34 15.77C346.34 16.04 346.34 17.39 346.34 19.83L343.53 19.83L340.72 19.83L340.72 24.04L340.72 28.25L343.53 28.25L346.34 28.25L346.34 41.22L346.34 54.19L351.61 54.19L356.88 54.19L356.88 41.22L356.88 28.25L361.1 28.25L365.32 28.25L365.32 24.04L365.32 19.83L361.03 19.83L356.81 19.83L357.02 16.82L357.24 13.87L362.01 13.66L366.72 13.45L366.72 9.31L366.72 5.11C362.84 5.11 360.69 5.11 360.26 5.11C356.25 5.18 352.81 5.53 351.47 6.16C348.1 7.56 346.34 10.93 346.34 15.77Z" />
      <path d="M521.36 23.55C520.43 22.67 519.91 22.17 519.81 22.08C516.09 18.71 509.76 18.15 504.07 20.81C498.3 23.48 495.21 28.67 495.07 35.82C494.93 43.95 498.51 50.41 504.84 53.49C509.69 55.81 517.28 55.67 522.41 53.14C524.52 52.16 527.05 50.27 528.03 49C531.55 44.38 531.9 42.06 531.9 22.92C531.9 21.73 531.9 15.79 531.9 5.11L526.63 5.11L521.36 5.11L521.36 14.36L521.36 23.55ZM520.09 32.45C523.82 39.82 518.47 47.95 511.38 45.85C507.72 44.73 505.89 41.71 505.89 36.73C505.89 33.44 506.17 32.74 508.28 30.63C510.88 28.04 513.91 27.55 517.14 29.16C518.12 29.65 519.46 31.12 520.09 32.45Z" />
      <path d="M174.91 36.59C174.56 43.81 174.28 45.43 173.22 46.06C172.38 46.62 171.68 46.62 170.76 46.06C169.71 45.36 169.57 43.81 169.36 32.52C169.35 31.68 169.3 27.45 169.22 19.83L163.94 19.83L158.67 19.83C158.67 27.53 158.67 31.81 158.67 32.66C158.67 44.59 158.81 45.71 160.22 48.58C164.3 56.65 177.51 57.49 183.13 50.13C184.89 47.81 185.03 47.18 185.24 38.84C185.52 29.93 186.23 27.55 188.55 27.55C190.86 27.55 191.57 29.93 191.85 38.84C192.06 47.18 192.2 47.81 193.96 50.13C196.49 53.49 200.07 55.03 205.13 55.03C210.69 55.03 214.69 52.86 216.87 48.58C218.28 45.71 218.42 44.59 218.42 32.66C218.42 31.81 218.42 27.53 218.42 19.83L213.15 19.83L207.87 19.83C207.79 27.45 207.74 31.68 207.73 32.52C207.52 43.81 207.38 45.36 206.33 46.06C205.41 46.62 204.71 46.62 203.87 46.06C202.81 45.43 202.53 43.81 202.18 36.59C201.76 27.69 201.06 25.02 198.24 22.5C194.66 19.27 187.35 18.22 182.57 20.25C176.67 22.78 175.4 25.51 174.91 36.59Z" />
      <path d="M228.05 48.65C233.04 56.23 244.85 57.49 249.98 50.97C250.07 50.84 250.57 50.21 251.45 49.07L251.45 51.67L251.45 54.19L256.79 54.19L262.14 54.19C261.97 47.54 261.87 43.85 261.86 43.11C261.5 31.33 260.94 29.02 257.36 24.81C254.4 21.31 250.47 19.62 244.42 19.34C240.98 19.2 238.17 19.48 236.41 20.11C225.45 24.18 221.3 38.49 228.05 48.65ZM250.82 39.68C250.05 42.97 248.92 44.31 246.04 45.5C239.57 48.23 233.67 41.36 235.92 33.79C237.54 28.53 243.79 26.56 247.87 30.07C250.47 32.24 251.59 36.03 250.82 39.68Z" />
      <path d="M307.47 47.95C311.9 52.86 314.5 53.77 324.76 54.05C325.36 54.07 328.33 54.16 333.69 54.33L333.69 50.13L333.69 45.92C329.09 45.75 326.54 45.66 326.03 45.64C319 45.43 318.16 45.29 316.54 43.74C313.02 40.45 313.73 40.17 325.96 40.17C326.71 40.17 330.46 40.17 337.2 40.17C337.2 37.52 337.2 36.04 337.2 35.75C337.2 29.93 334.6 24.6 330.39 21.66C327.93 19.97 326.59 19.62 322.3 19.41C319.49 19.27 316.26 19.48 315.13 19.83C303.89 23.41 299.6 39.26 307.47 47.95ZM327.01 32.24C327.43 33.86 327.36 33.86 320.76 33.86C320.31 33.86 318.09 33.86 314.08 33.86C314.54 32.59 314.8 31.89 314.85 31.75C316.33 27.48 321.53 26.14 324.83 29.09C325.75 30 326.73 31.4 327.01 32.24Z" />
      <path d="M368.9 33.79C367.01 45.71 374.95 55.03 387.04 55.03C394.7 55.03 400.18 52.02 403.41 46.06C408.62 36.45 404.96 25.37 395.19 20.81C391.11 18.92 384.86 18.64 380.57 20.11C374.53 22.29 369.89 27.69 368.9 33.79ZM393.36 43.25C390.55 46.83 383.45 46.9 381.13 43.39C375.51 34.91 382.75 24.88 391.32 29.3C395.47 31.47 396.6 39.12 393.36 43.25Z" />
      <path d="M454.58 42.62C454.58 43.39 454.58 47.25 454.58 54.19L459.86 54.19L465.13 54.19C465.13 47.5 465.13 43.79 465.13 43.04C465.13 31.96 465.13 31.96 466.95 30.07C470.33 26.77 476.16 28.11 477.71 32.59C478.13 33.79 478.48 39.12 478.48 44.45C478.48 45.1 478.48 48.34 478.48 54.19L483.82 54.19L489.17 54.19C489 47.12 488.9 43.2 488.88 42.41C488.53 29.16 487.83 26.77 482.91 22.71C477.99 18.71 469.63 17.87 463.37 20.81C455.78 24.39 454.58 27.34 454.58 42.62Z" />
      <path d="M269.09 35.89C272.4 45.64 274.72 51.53 275.7 52.51C279.22 56.02 286.24 56.02 289.76 52.51C290.74 51.53 293.13 45.57 296.37 35.89C299.11 27.62 301.36 20.67 301.36 20.32C301.36 20.04 298.9 19.83 295.94 19.83C295.58 19.83 293.75 19.83 290.46 19.83C288.35 27.28 287.18 31.42 286.95 32.24C285.05 39.05 283.15 44.59 282.73 44.52C282.38 44.52 280.55 38.91 278.65 32.17C278.42 31.35 277.28 27.24 275.21 19.83C271.88 19.83 270.03 19.83 269.66 19.83C266.56 19.83 264.1 20.04 264.1 20.32C264.1 20.67 266.35 27.62 269.09 35.89Z" />
      <path d="M416.7 50.48C419.93 53.56 423.8 54.96 428.86 55.03C436.52 55.17 442.07 52.3 444.67 46.9C446.01 44.24 446.15 42.62 446.15 31.89C446.15 31.09 446.15 27.07 446.15 19.83L441.3 19.83L436.45 19.83C436.28 26.48 436.19 30.17 436.17 30.91C435.96 43.18 435.75 43.81 431.53 45.57C429.42 46.41 428.86 46.41 426.61 45.29C423.02 43.53 422.32 41.08 422.32 29.44C422.32 28.8 422.29 25.6 422.25 19.83L417.33 19.83L412.41 19.83L412.41 31.75C412.69 41.76 414.12 48 416.7 50.48Z" />
      <path d="M541.04 24.18C539.77 26.28 539.63 27.69 539.63 40.31C539.63 41.23 539.63 45.86 539.63 54.19L544.9 54.19L550.17 54.19C550.17 46.66 550.17 42.48 550.17 41.64C550.17 27.55 549.89 28.25 555.87 28.25C556.1 28.25 557.25 28.25 559.31 28.25L559.31 24.04L559.31 19.83C555.64 19.83 553.6 19.83 553.2 19.83C549.47 19.9 546.1 20.25 544.76 20.88C543.5 21.45 541.81 22.92 541.04 24.18Z" />
      <path d="M565.08 44.66C566.13 48.16 570.98 53.14 574 53.84C574.15 53.87 574.87 54.04 576.18 54.33L576.18 61.27L576.18 68.22L581.45 68.22L586.72 68.22L586.72 61.56L586.72 54.96C588.54 54.16 589.55 53.72 589.75 53.63C593.4 52.02 596.92 48.09 597.9 44.59C598.32 43.11 598.67 36.94 598.67 30.84C598.67 30.11 598.67 26.44 598.67 19.83L593.4 19.83L588.13 19.83C588.13 26.14 588.13 29.65 588.13 30.35C588.13 36.52 587.78 41.5 587.36 42.48C585.53 46.48 579.77 47.18 576.74 43.67C574.78 41.57 574.78 41.57 574.78 30.7C574.78 29.98 574.78 26.35 574.78 19.83L569.5 19.83L564.23 19.83L564.23 30.77C564.47 38.86 564.75 43.49 565.08 44.66Z" />
      <path d="M56.11 36.95C70.46 40.83 85.74 39.27 99 32.58C104.12 29.25 109.06 25.64 113.79 21.77C114.94 20.93 115.19 19.32 114.34 18.19C114.26 18.07 114.16 17.96 114.06 17.86C106.07 11.37 95.57 1.46 69.67 3.22C53.4 4.24 40.43 14.98 36.51 18.57C35.82 19.22 35.77 20.29 36.38 21.01C39.57 24.6 49.01 34.75 56.11 36.95Z" />
      <path d="M115.28 23.44C112.86 25.23 109.26 28.04 105.91 30.42C107.53 31.78 111.95 36.19 112.72 36.82C114.31 38.1 116 40.37 118.92 40.06C122.93 39.62 131.89 36.58 131.87 32.55C131.84 28.51 126.63 23.3 123.95 22.53C122.19 22.4 120.42 22.64 118.75 23.22C117.63 23.58 116.44 23.66 115.28 23.44Z" />
      <path d="M115.28 23.44C112.86 25.23 109.26 28.04 105.91 30.42C107.53 31.78 111.95 36.19 112.72 36.82C114.31 38.1 116 40.37 118.92 40.06C122.93 39.62 131.89 36.58 131.87 32.55C131.84 28.51 126.63 23.3 123.95 22.53C122.19 22.4 120.42 22.64 118.75 23.22C117.63 23.58 116.44 23.66 115.28 23.44Z" />
      <path d="M68.56 42.4C68.56 42.4 75.41 42.25 77.39 42.04C84.67 41.48 91.7 39.16 97.89 35.3C84.39 41.32 69.21 42.46 54.96 38.54C48.06 36.31 38.91 26.68 35.19 22.5C35.07 22.65 34.15 23.83 34.03 23.98C32.75 25.52 30.47 25.73 28.93 24.46C28.75 24.31 28.59 24.15 28.45 23.98C23.51 20.02 16.84 18.99 10.95 21.27C3.7 24.24 1.92 32.55 1.87 37.38C1.87 39.79 9.11 46.27 14.38 46.79C21.05 47.58 27.7 45.09 32.22 40.11C34 38.25 36.49 37.24 39.08 37.33C42.67 36.93 46.28 38.07 49 40.47C48.37 44.45 43.93 46.77 44.46 51.07C45.26 57.79 53.06 74.45 59.6 75.56C68.7 77.1 92.75 72.78 92.44 64.54C92.39 62.03 85.94 61.19 80.15 60.13C70.8 58.56 67.47 51.62 65.87 47.04C65.06 44.62 68.02 43.33 68.56 42.4Z" />
      <path d="M68.56 42.4C68.56 42.4 75.41 42.25 77.39 42.04C84.67 41.48 91.7 39.16 97.89 35.3C84.39 41.32 69.21 42.46 54.96 38.54C48.06 36.31 38.91 26.68 35.19 22.5C35.07 22.65 34.15 23.83 34.03 23.98C32.75 25.52 30.47 25.73 28.93 24.46C28.75 24.31 28.59 24.15 28.45 23.98C23.51 20.02 16.84 18.99 10.95 21.27C3.7 24.24 1.92 32.55 1.87 37.38C1.87 39.79 9.11 46.27 14.38 46.79C21.05 47.58 27.7 45.09 32.22 40.11C34 38.25 36.49 37.24 39.08 37.33C42.67 36.93 46.28 38.07 49 40.47C48.37 44.45 43.93 46.77 44.46 51.07C45.26 57.79 53.06 74.45 59.6 75.56C68.7 77.1 92.75 72.78 92.44 64.54C92.39 62.03 85.94 61.19 80.15 60.13C70.8 58.56 67.47 51.62 65.87 47.04C65.06 44.62 68.02 43.33 68.56 42.4Z" />
    </svg>
  )
}

export default Logo;
