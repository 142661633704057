import React from "react";
import { Formik, Form } from "formik";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FormTextField from "./FormTextField";
import firebase from "../firebase";
import { useSnackbar } from "./Snackbar";
import { useCustomer } from "./Customer";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    "& .divider": {
      width: 100,
      height: 2,
      backgroundColor: theme.palette.primary.main,
      display: "block",
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
    },
    "& .actions": {
      marginTop: theme.spacing(2),
    },
  },
}));
interface ICustomer {
  email: string;
  companyTitle: string;
}
export type Customer = ICustomer | null;

interface CustomerDialogProps {
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
}
const CustomerDialog: React.FC<CustomerDialogProps> = ({
  dialogOpen,
  setDialogOpen,
}) => {
  const classes = useStyles();
  const { setSnackbarMessage } = useSnackbar();
  const customer = useCustomer();
  const handleClose = () => {
    setDialogOpen(false);
  };
  const initialValues = { email: "", companyTitle: "", password: "" };
  return (
    <Dialog open={dialogOpen} disableBackdropClick maxWidth="sm">
      <div className={classes.root}>
        <Typography variant="h5">Create Customer</Typography>
        <span className="divider" />
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            try {
              const createCustomer = firebase
                .functions()
                .httpsCallable("createCustomer");
              const {
                data: { id },
              } = await createCustomer(values);
              customer.setCustomerData((data) => {
                if (data && data.customers) {
                  return {
                    ...data,
                    customers: [
                      ...data.customers,
                      { companyTitle: values.companyTitle, customerId: id },
                    ],
                  };
                }
                return data;
              });
              setSnackbarMessage("Customer created");
              setDialogOpen(false);
            } catch (err) {
              console.log(err);
              window.alert("An error occurred - " + err.message);
              actions.setSubmitting(false);
            }
          }}
          validate={(values) => {
            const errors: { [key: string]: string } = {};
            if (!values.companyTitle) {
              errors.companyTitle = "Please enter a title";
            }
            if (!values.email) {
              errors.email = "Please enter an email";
            }
            if (values.password.length < 8) {
              errors.password = "Password must be 8 characters";
            }
            return errors;
          }}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <FormTextField name="companyTitle" label="Company Title" />
                <FormTextField name="email" label="Email" type="email" />
                <FormTextField
                  name="password"
                  label="Password"
                  type="password"
                />
                <Grid
                  container
                  className="actions"
                  alignItems="center"
                  justify="flex-end"
                  spacing={2}
                >
                  <Grid item>
                    <Button onClick={handleClose} type="button">
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {isSubmitting ? "SUBMITTING..." : "SUBMIT"}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Dialog>
  );
};

export default CustomerDialog;
