export const FIREBASE_CREDENTIALS = {
    apiKey: "AIzaSyDsFlDvm3zfzWQebThom1Oy8bdBjHCwfVA",
    authDomain: "wavefoundry-site.firebaseapp.com",
    databaseURL: "https://wavefoundry-site.firebaseio.com",
    projectId: "wavefoundry-site",
    storageBucket: "wavefoundry-site.appspot.com",
    messagingSenderId: "338869934712",
    appId: "1:338869934712:web:c46ee31b68a5dfa646ab8d"
}
export const ADMIN_UID = "Ztp4V4qooMR86fzRFHKGDuYfXxo2";
export const STRIPE_PUBLISHABLE_KEY = "pk_live_51HtPxeFWYJjGRfUJqsAOGUSOXb4RSfy3PSV9vhDMmtfaiOf9zdGiEZh840EGJ3zTzMvqVH9MZLVk3Tvjjtd00slJ00amYVPzW3";