import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Icon from "./Icon";
import { PaymentMethod } from "./PaymentMethodDialog";
import firebase from "../firebase";
import { CUSTOMERS } from "../constants";
import { useCustomer } from "./Customer";
import { useSnackbar } from "./Snackbar";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    height: 250,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    position: "relative",
    "& .default": {
      position: "absolute",
      top: 0,
      left: 0,
      color: green[600],
      padding: theme.spacing(1),
    },
    "& .delete-icon": {
      position: "absolute",
      top: 0,
      right: 0,
      color: theme.palette.grey[500],
    },
    "& .text": {
      margin: 0,
      lineHeight: 1.3,
    },
    "& .icon-container": {
      color: theme.palette.primary.main,
    },
    "&.big-button": {
      color: theme.palette.primary.main,
      outline: "none",
      border: "none",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },
    },
  },
}));

const validCardBrands = ["visa", "mastercard", "amex", "discover"];
const PaymentMethodContainer: React.FC<{
  paymentMethod: PaymentMethod;
  handleDelete: () => void;
}> = ({ paymentMethod, handleDelete }) => {
  const classes = useStyles();
  const customer = useCustomer();
  const { setSnackbarMessage } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSetAsDefaultClick = async () => {
    if (customer.customerData) {
      try {
        await firebase
          .firestore()
          .collection(CUSTOMERS)
          .doc(customer.customerData.uid)
          .update({ defaultPaymentMethodId: paymentMethod.id });
        customer.setCustomerData((current) => {
          if (current) {
            return { ...current, defaultPaymentMethodId: paymentMethod.id };
          }
          return null;
        });
        setSnackbarMessage("Payment method updated");
        setAnchorEl(null);
      } catch (err) {
        window.alert(`An error occurred - ${err.message}`);
      }
    }
  };
  const handleDeleteClick = () => {
    handleDelete();
    setAnchorEl(null);
  };
  const iconName = validCardBrands.includes(paymentMethod.brand)
    ? paymentMethod.brand
    : "creditCard";
  return (
    <Grid item xs={12} md={4}>
      <div className={classes.paper}>
        {customer.customerData &&
          customer.customerData.defaultPaymentMethodId === paymentMethod.id && (
            <div className="default">
              <Typography variant="body2" color="inherit">
                <strong>DEFAULT</strong>
              </Typography>
            </div>
          )}
        <div className="delete-icon">
          <Tooltip title="Edit payment method">
            <div>
              <IconButton onClick={handleButtonClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={`payment-method-${paymentMethod.id}`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleSetAsDefaultClick}>
                  Set as default payment method
                </MenuItem>
                <MenuItem onClick={handleDeleteClick}>
                  Delete payment method
                </MenuItem>
              </Menu>
            </div>
          </Tooltip>
        </div>
        <div className="icon-container">
          <Icon iconName={iconName} width={70} />
        </div>
        <div>
          <Typography variant="h6" className="text">
            {paymentMethod.brand.toUpperCase()} ...
            {paymentMethod.last4}
          </Typography>
        </div>
        <div>
          <Typography variant="h6" className="text">
            Exp: {paymentMethod.expMonth}/{paymentMethod.expYear}
          </Typography>
        </div>
      </div>
    </Grid>
  );
};

export default PaymentMethodContainer;
