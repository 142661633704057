import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import CreateInvoiceDialog from "./CreateInvoiceDialog";
import InvoiceDialog, { Invoice, defaultInvoiceProps } from "./InvoiceDialog";
import { useCustomer } from "./Customer";
import firebase from "../firebase";
import { CUSTOMERS, INVOICES, INVOICE_LIMIT } from "../constants";
import { updateInvoices } from "./Root";
import LoadingIcon from "./LoadingIcon";
import InvoiceTableList from "./InvoiceTableList";
import InvoicePaperList from "./InvoicePaperList";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .no-invoices": {
      marginTop: theme.spacing(4),
    },
    padding: theme.spacing(3),
  },
  tableContainer: {
    margin: `auto`,
    maxWidth: 1200,
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: theme.palette.grey[300],
    display: "block",
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(3)}px 0`,
  },
  footer: {
    marginTop: theme.spacing(2),
    "& .link": {
      color: theme.palette.primary.main
    }
  }
}));

const InvoiceTab: React.FC = () => {
  const classes = useStyles();
  const customer = useCustomer();
  const [loadingInvoices, setLoadingInvoices] = React.useState(false);
  const handleRefresh = async () => {
    if (customer.customerData) {
      setLoadingInvoices(true);
      const now = Date.now();
      const invoices: Invoice[] = [];
      const invoiceDocs = await firebase
        .firestore()
        .collection(CUSTOMERS)
        .doc(customer.customerData.uid)
        .collection(INVOICES)
        .get();
      invoiceDocs.forEach((doc) => {
        updateInvoices(doc, invoices, now);
      });
      customer.setCustomerData((data) => {
        if (data) {
          return {
            ...data,
            invoices,
          };
        }
        return data;
      });
      setLoadingInvoices(false);
    }
  };
  const [activeInvoice, setActiveInvoice] = React.useState<Invoice | null>(
    null
  );
  const invoiceProps = React.useMemo(() => {
    if (customer.customerData && customer.customerData.isAdmin) {
      return [{ label: "Company", key: "companyName" }, ...defaultInvoiceProps];
    }
    return defaultInvoiceProps;
  }, [customer]);
  const invoices = customer.customerData
    ? customer.customerData.invoices.sort((a) => {
        if (a.status === "Paid") {
          return 1;
        }
        return -1;
      })
    : [];
  const renderInvoices = () => {
    if (loadingInvoices) {
      return <LoadingIcon />;
    }
    if (customer.customerData && customer.customerData.invoices.length > 0) {
      const invoiceListProps = {
        invoices,
        invoiceProps,
        setActiveInvoice,
      };
      return (
        <>
          <Hidden mdDown>
            <InvoiceTableList {...invoiceListProps} />
          </Hidden>
          <Hidden lgUp>
            <InvoicePaperList {...invoiceListProps} />
          </Hidden>
          {customer.customerData.invoices.length === INVOICE_LIMIT && <div>
            <Typography align="center" variant="body2" color="textSecondary" className={classes.footer}>
              Currently displaying your {INVOICE_LIMIT} latest invoices. If you
              wish to see older invoices, please contact us at
              {' '}<a href="mailto:hello@wavefoundry.io" className="link">hello@wavefoundry.io</a>
            </Typography>
          </div>}
        </>
      );
    }
    return (
      <Typography color="textSecondary" className="no-invoices" align="center">
        No invoices to show
      </Typography>
    );
  };
  return (
    <div>
      <div className={classes.root}>
        <div className={classes.tableContainer}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5">Invoices</Typography>
            </Grid>
            <Grid item>
              {customer.customerData && customer.customerData.isAdmin ? (
                <CreateInvoiceDialog />
              ) : (
                <Button
                  variant="outlined"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                >
                  Refresh
                </Button>
              )}
            </Grid>
            <span className={classes.divider} />
          </Grid>
          {renderInvoices()}
        </div>
      </div>
      <InvoiceDialog
        activeInvoice={activeInvoice}
        setActiveInvoice={setActiveInvoice}
      />
    </div>
  );
};

export default InvoiceTab;
