import firebase from '../firebase';

export const handleError = (err: Error) => {
    window.alert(`An error occurred - ${err.message}`);
}
export const PRICE_REG_EX = /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])$/;

interface InputFieldProps {
    name: string;
    initialValue?: any;
    formatFunction?: (value: any) => any;
    imageType?: 'default' | 'cropped';
}

interface CroppedImageField extends InputFieldProps {
    imageType: 'cropped';
    croppedImageProps: { width: number; height: number };
}
export type InputField = InputFieldProps | CroppedImageField;

interface CropValues {
    aspect: number;
    unit: 'px' | '%';
    width: number;
    height: number;
    x: number;
    y: number;
}

export interface CropImageInputValues {
    cropValues: CropValues;
    previewSource: string | undefined;
    inputValue: string | number | readonly string[] | undefined;
    labelValue: string;
    file: File | null;
}

export interface ImageInputValues {
    file: File | null;
    blob: string;
    imageURL: string;
}

function generateInitialCropImageValues(width: number, height: number): CropImageInputValues {
    const aspect = width/height;
    const cropWidth = width * 0.5;
    const cropHeight = aspect * cropWidth;
    return {
        cropValues: {
            aspect,
            unit: 'px',
            width: cropWidth,
            height: cropHeight,
            x: 0,
            y: 0
        },
        previewSource: '',
        inputValue: undefined,
        labelValue: '',
        file: null
    }
}
export const generateInitialValues = (fields: InputField[], editValues: any) => {
    const initialValues: { [key: string]: any } = {};
    if (editValues) {
        fields.forEach(field => {
            if (field.imageType === 'cropped') {
                initialValues[field.name] = editValues[field.name];
                initialValues[field.name].file = null;
            }
            else if (field.imageType === 'default') {
                const imageValues: ImageInputValues = {
                    file: null,
                    blob: '',
                    imageURL: editValues[field.name].imageURL
                }
                initialValues[field.name] = imageValues;
            }
            else if (field.formatFunction) {
                initialValues[field.name] = field.formatFunction(editValues[field.name]);
            }
            else {
                initialValues[field.name] = editValues[field.name];
            }
        })
    }
    else {
        fields.forEach((field) => {
            if (field.imageType === 'cropped') {
                const imageField = field as CroppedImageField;
                initialValues[field.name] = generateInitialCropImageValues(imageField.croppedImageProps.width, imageField.croppedImageProps.height);
            }
            else if (field.imageType === 'default') {
                const imageValues: ImageInputValues = {
                    file: null,
                    blob: '',
                    imageURL: ''
                }
                initialValues[field.name] = imageValues;
            }
            else {
                initialValues[field.name] = field.initialValue === false ? false : field.initialValue || '';
            }
        })
    }
    return initialValues;
}
export function formatPhone(number: string) {
    return `(${number.slice(0,3)}) ${number.slice(3,6)}-${number.slice(6)}`;
}
export function convertPriceToCents(price: string) {
    return parseInt(price.replace(/\D/g,''));
}
export function convertCentsToCurrency(cents: number) {
    return `${(cents/100).toFixed(2)}`
}
interface FirestoreImageValues {
    cropValues: CropValues;
    previewSource: CropImageInputValues["previewSource"];
    fileName: string;
    labelValue: string;
}
export const processCroppedImage = async (imageInputValues: CropImageInputValues, imagePropertyName: string, editValues: any): Promise<FirestoreImageValues> => {
    const firestoreImageValues = {
        cropValues: imageInputValues.cropValues,
        previewSource: imageInputValues.previewSource,
        labelValue: imageInputValues.labelValue
    }
    if (imageInputValues.file) {
        const storageRef = firebase.storage().ref();
        const fileName = `cropped-image-${Date.now()}`;
        await storageRef.child(fileName).put(imageInputValues.file);
        return {
            ...firestoreImageValues,
            previewSource: '', // since this will be a blob, we don't need to store that massive string
            fileName
        };
    }
    return {
        ...firestoreImageValues,
        fileName: editValues[imagePropertyName].fileName
    };
}

export const processImage = async (imageInputValues: ImageInputValues): Promise<string> => {
    if (imageInputValues.file) {
        const storageRef = firebase.storage().ref();
        const fileName = `image-${Date.now()}`;
        const image = await storageRef.child(fileName).put(imageInputValues.file);
        const imageURL = await image.ref.getDownloadURL();
        return imageURL;
    }
    return '';
}