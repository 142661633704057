import React from 'react';

const useHeader = () => {
    const [tabValue, setTabValue] = React.useState(0);
    function handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        setTabValue(newValue);
    }
    return { tabValue, handleTabChange }
}

export default useHeader;