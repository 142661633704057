import React from "react";
import { Formik, Form } from "formik";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "./Snackbar";
import FormSelectField from "./FormSelectField";
import { useCustomer } from "./Customer";
import FormPriceField from "./FormPriceField";
import { Invoice } from "./InvoiceDialog";
import { convertPriceToCents, PRICE_REG_EX } from "../utils";
import firebase from "../firebase";
import { CUSTOMERS, INVOICES } from "../constants";
import FormTextField from "./FormTextField";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    "& .divider": {
      width: 100,
      height: 2,
      backgroundColor: theme.palette.primary.main,
      display: "block",
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
    },
    "& .actions": {
      marginTop: theme.spacing(2),
    },
  },
}));

const categories = [
  "Retainer Fee",
  "Finished Website",
  "Billable Hours",
  "Annual Maintenance",
  "Monthly Billing"
].map((val) => ({ label: val, value: val }));
const CreateInvoiceDialog: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { setSnackbarMessage } = useSnackbar();
  const customer = useCustomer();
  const customerObject: { [key: string]: string } = {};
  const customerList: { value: string; label: string }[] = [];
  if (customer.customerData && customer.customerData.customers) {
    for (const { customerId, companyTitle } of customer.customerData
      .customers) {
      customerObject[customerId] = companyTitle;
      customerList.push({ label: companyTitle, value: customerId });
    }
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <Button variant="outlined" startIcon={<AddIcon />} onClick={handleOpen}>
        CREATE INVOICE
      </Button>
      <Dialog open={open} disableBackdropClick maxWidth="sm" fullWidth>
        <div className={classes.root}>
          <Typography variant="h5">Create Invoice</Typography>
          <span className="divider" />
          <Formik
            initialValues={{
              customerId: "",
              category: "",
              total: "",
              notes: "Thanks for choosing to work with Wavefoundry!",
            }}
            onSubmit={async (values, actions) => {
              try {
                const now = Date.now();
                const total = convertPriceToCents(values.total);
                const invoice: Invoice = {
                  ...values,
                  createdAt: now,
                  amountPaid: 0,
                  total,
                  amountDue: total,
                  paidAt: null,
                  status: "Not Paid",
                  dueDate: now + (10 * 24 * 60 * 60 * 1000), // 10 days
                  companyName: customerObject[values.customerId],
                };
                const newInvoice = await firebase
                  .firestore()
                  .collection(CUSTOMERS)
                  .doc(values.customerId)
                  .collection(INVOICES)
                  .add(invoice);
                customer.setCustomerData((data) => {
                  if (data) {
                    return {
                      ...data,
                      invoices: [
                        ...data.invoices,
                        { ...invoice, id: newInvoice.id },
                      ],
                    };
                  }
                  return data;
                });
                setSnackbarMessage("Invoice created");
                setOpen(false);
              } catch (err) {
                console.log(err);
                window.alert("An error occurred");
                actions.setSubmitting(false);
              }
            }}
            validate={(values) => {
              const errors: { [key: string]: string } = {};
              if (!values.customerId) {
                errors.customerId = "Select a customer";
              }
              if (!values.category) {
                errors.category = "Select a category";
              }
              if (PRICE_REG_EX.test(values.total) === false) {
                errors.total = "Enter a value in dollars and cents";
              }
              return errors;
            }}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <FormSelectField
                    name="customerId"
                    label="Customer"
                    options={customerList}
                  />
                  <FormSelectField
                    name="category"
                    label="Category"
                    options={categories}
                  />
                  <FormPriceField label="Total" name="total" />
                  <FormTextField label="Notes" name="notes" placeholder="E.g. '8 hours of development" multiline />
                  <Grid
                    container
                    className="actions"
                    alignItems="center"
                    justify="flex-end"
                    spacing={2}
                  >
                    <Grid item>
                      <Button onClick={handleClose} type="button">
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={handleClose}
                        type="submit"
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                      >
                        {isSubmitting ? "SUBMITTING..." : "SUBMIT"}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </>
  );
};

export default CreateInvoiceDialog;
