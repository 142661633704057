import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "../firebase";
import { useCustomer } from "./Customer";
import { CUSTOMERS, PAYMENT_METHODS } from "../constants";
import { useSnackbar } from "./Snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    "& .divider": {
      width: 100,
      height: 2,
      background: theme.palette.grey[300],
      display: "block",
      margin: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
    },
    "& .actions": {
      marginTop: theme.spacing(2),
    },
  },
}));

const DeletePaymentMethodDialog: React.FC<{
  paymentMethodId: string;
  setPaymentMethodId: (id: string) => void;
}> = ({ paymentMethodId, setPaymentMethodId }) => {
  const classes = useStyles();
  const customer = useCustomer();
  const { setSnackbarMessage } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  React.useEffect(() => {
    if (paymentMethodId) {
      setOpen(true);
    }
  }, [paymentMethodId]);
  const handleExited = () => {
    setPaymentMethodId("");
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async () => {
    if (customer.customerData) {
      setDeleting(true);
      await firebase
        .firestore()
        .collection(CUSTOMERS)
        .doc(customer.customerData.uid)
        .collection(PAYMENT_METHODS)
        .doc(paymentMethodId)
        .delete();
      if (customer.customerData.defaultPaymentMethodId === paymentMethodId) {
        await firebase
          .firestore()
          .collection(CUSTOMERS)
          .doc(customer.customerData.uid)
          .update({ defaultPaymentMethodId: "" });
      }
      customer.setCustomerData((data) => {
        if (data) {
          const defaultPaymentMethodId =
            data.defaultPaymentMethodId === paymentMethodId
              ? ""
              : data.defaultPaymentMethodId;
          return {
            ...data,
            defaultPaymentMethodId,
            paymentMethods: data.paymentMethods.filter(
              ({ id }) => id !== paymentMethodId
            ),
          };
        }
        return data;
      });
      setSnackbarMessage("Payment method deleted");
      handleClose();
      setDeleting(false);
    }
  };
  return (
    <Dialog open={open} maxWidth="sm" onExited={handleExited}>
      <div className={classes.root}>
        <Typography variant="h6">Delete Payment Method</Typography>
        <span className="divider" />
        <Typography>
          Are you sure you want to delete this payment method? This action
          cannot be undone.
        </Typography>
        <Grid container spacing={2} justify="flex-end" className="actions">
          <Grid item>
            <Button onClick={handleClose}>CANCEL</Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={deleting}
              onClick={handleDelete}
            >
              {deleting ? "DELETING..." : "DELETE"}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

export default DeletePaymentMethodDialog;
