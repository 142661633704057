import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    "& .icon-container": {
      background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.light})`,
      color: theme.palette.common.white,
      width: 100,
      height: 100,
      margin: 'auto',
      borderRadius: "50%",
      boxShadow: theme.shadows[2],
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: theme.spacing(2),
      fontSize: 60
    },
    "& .mb-2": {
      marginBottom: theme.spacing(2),
    },

  },
}));

const PaymentSuccessDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
}> = ({ open, handleClose }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose}>
      <div className={classes.root}>
        <div className="icon-container">
          <CheckIcon fontSize="inherit" />
        </div>
        <div className="mb-2">
          <Typography variant='h5' color='primary' align='center' gutterBottom><strong>Success!</strong></Typography>
          <Typography align="center">
            Thank you for your payment; we'll send you an email confirmation
            shortly. Please note that it may take a few minutes for your invoice to be updated on this page.
          </Typography>
        </div>
        <div>
          <Button variant="outlined" fullWidth onClick={handleClose}>
            CLOSE
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
export default PaymentSuccessDialog;
