import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import { useCustomer } from "./Customer";
import CustomerDialog, { Customer } from "./CustomerDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  listContainer: {
    margin: `auto`,
    maxWidth: 1000,
  },
  paper: {
    padding: theme.spacing(2),
    height: 250,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    position: "relative",
    "& .delete-icon": {
      position: "absolute",
      top: 0,
      right: 0,
      color: theme.palette.grey[500],
    },
    "& .text": {
      margin: 0,
      lineHeight: 1.3,
    },
    "&.big-button": {
      color: theme.palette.primary.main,
      outline: "none",
      border: "none",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },
    },
  },
  divider: {
    width: 100,
    height: 4,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    display: "block",
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(3)}px 0`,
  },
}));

const CustomerTab: React.FC = () => {
  const classes = useStyles();
  const customer = useCustomer();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleOpen = () => {
    setDialogOpen(true);
  };
  if (!customer.customerData || !customer.customerData.customers) {
    return null;
  }
  return (
    <div>
      <div className={classes.root}>
        <div className={classes.listContainer}>
          <Typography variant="h5">All Customers</Typography>
          <span className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <button
                className={`${classes.paper} big-button`}
                onClick={handleOpen}
              >
                <div>
                  <AddIcon fontSize="large" />
                </div>
                <div>
                  <Typography>ADD CUSTOMER</Typography>
                </div>
              </button>
            </Grid>
            {customer.customerData.customers.map((customer, key) => {
              return (
                <Grid item xs={12} md={4} key={`cus-${key}`}>
                  <div className={classes.paper}>
                    <div>
                      <Typography variant="h5">
                        {customer.companyTitle}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
      <CustomerDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
    </div>
  );
};

export default CustomerTab;
