import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AccountIcon from "@material-ui/icons/AccountCircle";
import LogOutIcon from "@material-ui/icons/ExitToApp";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import firebase from "../firebase";
import Logo from "./Logo";
import LogoSmall from "./LogoSmall";
import Hidden from "@material-ui/core/Hidden";
import { useCustomer } from "./Customer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menu: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  logoContainer: {
    display: "flex",
    flexGrow: 1,
    color: theme.palette.common.white,
    alignItems: "center",
    letterSpacing: 1,
    "& .logo": {
      width: 200,
      marginRight: theme.spacing(2.5),
      "& svg": {
        verticalAlign: "middle",
      },
    },
    "& .logo-small": {
      width: 44,
      marginRight: theme.spacing(1.5),
      "& svg": {
        verticalAlign: "middle",
      },
    },
    "& .text": {
      letterSpacing: 4,
    },
  },
  navButton: {
    fontSize: 30,
  },
}));

const Navbar: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  const customer = useCustomer();
  const handleLogout = () => {
    firebase.auth().signOut();
  };
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <div className={classes.logoContainer}>
            <Hidden xsDown>
              <div className="logo">
                <Logo />
              </div>
            </Hidden>
            <Hidden smUp>
              <div className="logo-small">
                <LogoSmall />
              </div>
            </Hidden>
            <div>
              <Typography className="text" variant="h6">
                PORTAL
              </Typography>
            </div>
          </div>
          <div>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Tooltip title="Account details">
                  <IconButton
                    aria-controls="account-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    color="inherit"
                    className={classes.navButton}
                  >
                    <AccountIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="account-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <div className={classes.menu}>
                    <Typography variant="caption" color="textSecondary">
                      Logged in as:
                    </Typography>
                    <Typography gutterBottom>
                      <strong>{customer.customerData?.companyTitle}</strong>
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      Email:
                    </Typography>
                    <Typography gutterBottom>
                      <strong>{customer.customerData?.email}</strong>
                    </Typography>
                  </div>
                </Menu>
              </Grid>
              <Grid item>
                <Tooltip title="Log out">
                  <IconButton
                    color="inherit"
                    onClick={handleLogout}
                    className={classes.navButton}
                  >
                    <LogOutIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
