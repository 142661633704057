import React from "react";
import { Invoice } from "./InvoiceDialog";
import { PaymentMethod } from "./PaymentMethodDialog";

export interface ICustomer {
    companyTitle: string;
    uid: string;
    email: string;
    isAdmin: boolean;
    defaultPaymentMethodId?: string;
    invoices: Invoice[];
    paymentMethods: PaymentMethod[];
    customers?: { customerId: string; companyTitle: string }[];
}

type Customer = ICustomer | null;

const CustomerContext = React.createContext<[Customer, React.Dispatch<React.SetStateAction<Customer>>]>([null, () => null]);

const CustomerProvider: React.FC = (props) => {
  const [customerData, setCustomerData] = React.useState<Customer>(null);

  return (
    <CustomerContext.Provider value={[customerData, setCustomerData]}>
      {props.children}
    </CustomerContext.Provider>
  );
};

const useCustomer = () => {
  const [customerData, setCustomerData] = React.useContext(CustomerContext);
  return { customerData, setCustomerData };
};

export { CustomerProvider, useCustomer };
