import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../config';
import Header from "./Header";
import useHeader from "./useHeader";
import InvoiceTab from "./InvoiceTab";
import PaymentMethodTab from "./PaymentMethodTab";
import CustomerTab from "./CustomerTab";
import { useCustomer } from "./Customer";

const defaultTabLabels = ["Invoices", "Payment Methods"];

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
const tabs = [<InvoiceTab />, <PaymentMethodTab />, <CustomerTab />];

const MainPage: React.FC = () => {
  const headerProps = useHeader();
  const customer = useCustomer();
  const tabLabels = React.useMemo(() => {
    if (customer && customer.customerData && customer.customerData.isAdmin) {
      return [...defaultTabLabels, "Customers"];
    }
    return defaultTabLabels;
  }, [customer])
  return (
    <Elements stripe={stripePromise}>
      <Header {...headerProps} tabLabels={tabLabels} />
      {tabs[headerProps.tabValue]}
    </Elements>
  );
};

export default MainPage;
