import React from "react";
import Grid from "@material-ui/core/Grid";
import { green } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { InvoiceTableListProps } from "./InvoiceTableList";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .status": {
      fontWeight: "bold",
      "&.paid": {
        color: green[700],
      },
      "&.overdue": {
        color: theme.palette.error.main,
      },
    },
  },
  paper: {
    padding: theme.spacing(1),
  },
  actions: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

const InvoicePaperList: React.FC<InvoiceTableListProps> = ({
  invoices,
  invoiceProps,
  setActiveInvoice,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {invoices.map((invoice, index) => {
          const handleClick = () => {
            setActiveInvoice(invoice);
          };
          return (
            <Grid item key={`invoice-${index}`} xs={12} md={6}>
              <Paper>
                <div className={classes.paper}>
                  <Grid container>
                    {invoiceProps.map(({ label, key, renderFunction }) => {
                      return (
                        <Grid item key={key} xs={6}>
                          <Typography variant="caption" color="textSecondary">
                            {label}
                          </Typography>
                          <Typography gutterBottom>
                            {renderFunction
                              ? renderFunction(invoice[key])
                              : invoice[key]}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <div className={classes.actions}>
                    <div>
                      <Button
                        onClick={handleClick}
                        variant="outlined"
                        size="small"
                      >
                        VIEW INVOICE
                      </Button>
                    </div>
                  </div>
                </div>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default InvoicePaperList;
