import React from "react";
import { makeStyles } from "@material-ui/core";
import fireType from "firebase";
import Navbar from "./Navbar";
import LoadingIcon from "./LoadingIcon";
import LoginPage from "./LoginPage";
import { SnackbarProvider, SnackbarElement } from "./Snackbar";
import firebase from "../firebase";
import MainPage from "./MainPage";
import { useCustomer } from "./Customer";
import { CUSTOMERS, PAYMENT_METHODS, INVOICES, INVOICE_LIMIT } from "../constants";
import { ADMIN_UID } from "../config";
import { Invoice } from "./InvoiceDialog";
import { PaymentMethod } from "./PaymentMethodDialog";
import { ICustomer } from "./Customer";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
    display: "flex",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

const now = Date.now();
export function updateInvoices(
  doc: fireType.firestore.QueryDocumentSnapshot<fireType.firestore.DocumentData>,
  invoices: Invoice[],
  currentTimestamp: number
) {
  const data = doc.data() as Invoice;
  const status =
    data.status !== "Paid"
      ? currentTimestamp > data.dueDate
        ? "Overdue"
        : "Not Paid"
      : "Paid";
  invoices.push({ ...data, id: doc.id, status });
}
const Root = () => {
  const [authenticating, setAuthenticating] = React.useState(true);
  const { customerData, setCustomerData } = useCustomer();
  const classes = useStyles();
  React.useEffect(() => {
    const handleRefresh = () => {
      firebase.auth().onAuthStateChanged(async (user) => {
        try {
          if (user) {
            const [
              customerDoc,
              paymentMethodDocs,
              invoiceDocs,
            ] = await Promise.all([
              firebase.firestore().collection(CUSTOMERS).doc(user.uid).get(),
              firebase
                .firestore()
                .collection(CUSTOMERS)
                .doc(user.uid)
                .collection(PAYMENT_METHODS)
                .get(),
              firebase
                .firestore()
                .collection(CUSTOMERS)
                .doc(user.uid)
                .collection(INVOICES).limit(INVOICE_LIMIT).orderBy('createdAt', 'desc')
                .get(),
            ]);
            const customer = customerDoc.data();
            const invoices: Invoice[] = [];
            const paymentMethods: PaymentMethod[] = [];
            paymentMethodDocs.forEach((doc) => {
              const data = doc.data() as PaymentMethod;
              paymentMethods.push(data);
            });
            invoiceDocs.forEach((doc) => {
              updateInvoices(doc, invoices, now);
            });
            if (customer) {
              const isAdmin = user.uid === ADMIN_UID;
              const newCustomerData: ICustomer & { [key: string]: any } = {
                uid: user.uid,
                isAdmin,
                companyTitle: customer.companyTitle,
                defaultPaymentMethodId: customer.defaultPaymentMethodId,
                invoices,
                paymentMethods,
                email: user.email || "",
              };
              newCustomerData.paymentMethods.sort((a) => {
                return newCustomerData.defaultPaymentMethodId === a.id ? -1 : 1;
              });
              if (isAdmin) {
                const customers: {
                  companyTitle: string;
                  customerId: string;
                }[] = [];
                const customerDocs = await firebase
                  .firestore()
                  .collection(CUSTOMERS)
                  .get();
                for (const doc of customerDocs.docs) {
                  const data = doc.data();
                  if (data.companyTitle !== "Wavefoundry") {
                    customers.push({
                      companyTitle: data.companyTitle,
                      customerId: doc.id,
                    });
                  }
                  const customerInvoiceDocs = await firebase
                    .firestore()
                    .collection(CUSTOMERS)
                    .doc(doc.id)
                    .collection(INVOICES)
                    .get();
                  customerInvoiceDocs.forEach((doc) => {
                    updateInvoices(doc, invoices, now);
                  });
                }
                newCustomerData.customers = customers;
              }
              setCustomerData(newCustomerData);
            } else {
              setCustomerData(null);
            }
            setAuthenticating(false);
          } else {
            setCustomerData(null);
            setAuthenticating(false);
          }
        } catch (err) {
          console.log(err);
          window.alert("An error occurred - please try again later");
          setAuthenticating(false);
        }
      });
    };
    handleRefresh();
  }, [setCustomerData]);
  function renderContent() {
    if (authenticating) {
      return (
        <div className={classes.root}>
          <LoadingIcon text="Loading..." />
        </div>
      );
    } else if (!customerData) {
      return <LoginPage />;
    }
    return (
      <SnackbarProvider>
        <Navbar />
        <MainPage />
        <SnackbarElement />
      </SnackbarProvider>
    );
  }
  return renderContent();
};

export default Root;
