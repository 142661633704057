import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Invoice, InvoiceProp } from "./InvoiceDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .status": {
      fontWeight: "bold",
      "&.paid": {
        color: green[700],
      },
      "&.overdue": {
        color: theme.palette.error.main,
      },
    },
  },
  table: {
    minWidth: 1000,
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

export interface InvoiceTableListProps {
  invoices: Invoice[];
  invoiceProps: InvoiceProp[];
  setActiveInvoice: (invoice: Invoice) => void;
}
const InvoiceTableList: React.FC<InvoiceTableListProps> = ({
  invoices,
  invoiceProps,
  setActiveInvoice,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {invoiceProps.map(({ label }) => {
                return (
                  <TableCell key={label}>
                    <strong>{label}</strong>
                  </TableCell>
                );
              })}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((invoice, index) => {
              const handleClick = () => {
                setActiveInvoice(invoice);
              };
              return (
                <TableRow key={`row-${index}`}>
                  {invoiceProps.map(({ key, renderFunction }) => {
                    return (
                      <TableCell key={`${key}-${index}`}>
                        {renderFunction
                          ? renderFunction(invoice[key])
                          : invoice[key]}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleClick}
                    >
                      VIEW INVOICE
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default InvoiceTableList;
