import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import { useCustomer } from "./Customer";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 0,
    position: "relative",
  },
  toolbar: {
    minHeight: "auto",
  },
}));

interface HeaderProps {
  tabValue: number;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  tabLabels: string[];
}
const Header: React.FC<HeaderProps> = ({
  tabValue,
  handleTabChange,
  tabLabels,
}) => {
  const classes = useStyles();
  const customer = useCustomer();
  return (
    <Paper className={classes.paper} elevation={2}>
      <Toolbar className={classes.toolbar}>
        <Grid container justify="space-between" alignItems="center">
          <Hidden mdDown>
            <Grid item xs={6}>
              <Typography variant="h5">
                <strong>{customer.customerData?.companyTitle}</strong>
              </Typography>
            </Grid>
          </Hidden>
          <Grid item md="auto" xs={12}>
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="Header tabs"
            >
              {tabLabels.map((label) => {
                return <Tab label={label} key={label} />;
              })}
            </Tabs>
          </Grid>
        </Grid>
      </Toolbar>
    </Paper>
  );
};

export default Header;
